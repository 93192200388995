img{
height: 300px;
margin-top: 0px;
}
.header-root{
    background-color: rgb(44, 103, 130);
    color: white;
}
header{
    font-family: 'Nunito', sans-serif;
}
